export function reactiveWithDefaults<T extends object> (source: T, defaults: Partial<T>): T {
  const returnObj = reactive({ ...defaults, ...source }) as T

  watch(source, () => {
    Object.assign(returnObj, source)
  }, { deep: true })

  watch(returnObj, () => {
    Object.assign(source, returnObj)
  }, { deep: true })

  return returnObj
}
